import { livisAlert } from "./alert";
import { getLivisUserInfo } from "./storage";

export const checkUppercase = (str: string) => {
  return /[A-Z]/.test(str);
};

export const checkLowercase = (str: string) => {
  return /[a-z]/.test(str);
};

export const checkNumber = (str: string) => {
  return /[0-9]/.test(str);
};

export const checkSpecialCharacter = (str: string) => {
  return /[@$!%*#?&]/.test(str);
};

export const removeUnderscores = (str: any) => {
  if (!str?.length) return str;
  return str.replace(/_/g, " ");
};

export const formatString = (str: string) => {
  if (!str?.length) return str;

  const separateWord = str?.split(" ");
  separateWord?.forEach((word, index) => {
    separateWord[index] = word?.charAt(0)?.toUpperCase() + word?.substring(1);
  });
  return separateWord?.join(" ");
};

export const formatFirstCharacter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatSize = (data: number) => {
  const data_size = (data / 1024).toFixed(1);
  return data_size + " GB";
};

export const formatImageSize = (data: number) => {
  if (data >= 1073741824) {
    const data_size = Math.floor(data / 1073741824);
    return data_size.toFixed(2) + " GB";
  } else if (data >= 1048576) {
    const data_size = Math.floor(data / 1048576);
    return data_size.toFixed(2) + " MB";
  } else if (data >= 1024) {
    const data_size = Math.floor(data / 1024);
    return data_size.toFixed(2) + " KB";
  } else {
    return data?.toFixed(2) + " Bytes";
  }
};

export const formatCount = (data: any): string => {
  if (data === 0) {
    return "0";
  } else if (data == null) {
    return "--";
  } else {
    const formattedValue = data.toString();
    return formattedValue.length === 1
      ? formattedValue
      : formattedValue.padStart(2, "0");
  }
};

export const showAlert = (msg: any, status: number) => {
  //console.log(msg, status);
  switch (true) {
    case status >= 200 && status < 300:
      livisAlert(msg, "success");
      break;
  }
};

export const formatSecond = (seconds: any) => {
  const formatSeconds = new Date(seconds * 1000)?.toISOString()?.slice(11, 19);
  return formatSeconds;
};

export const alert_error = (response: any) => {
  switch (response?.status) {
    case 500:
      livisAlert("Internal server error", "error");
      break;
    default:
      livisAlert(response?.data?.message, "error");
  }
};

export const formatETA = (second: number) => {
  const seconds = Math.floor(second);
  switch (true) {
    case seconds === 1:
      return `${seconds} sec`;
    case seconds > 1 && seconds < 60:
      return `${seconds} secs`;
    case seconds >= 60 && seconds < 3600: {
      const min = Math.floor(seconds / 60);
      const secs = seconds % 60;
      if (secs === 0) return `${min} mins`;
      else return `${min}:${secs} mins`;
    }
    case seconds >= 3600: {
      const totalMinutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours}:${minutes}:${secs} hrs`;
    }
    default:
      return "0 secs";
  }
};

export const convertUTCDateToLocalDate = (
  utc: any,
  format: any = false
): any => {
  if (!utc) return;

  let utcDate: any;

  if (format) {
    utcDate = new Date(utc);
  } else {
    utcDate = new Date(utc + "Z");
  }

  // console.log(utcDate)
  // Use toLocaleString directly on the Date object
  const localTimeString = utcDate.toLocaleString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return localTimeString.replace("00", "12");

  // const date = new Date(utc + "Z");
  // const local_time = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

  // const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  // const offset = date.getTimezoneOffset() / 60;
  // const hours = date.getHours();

  // newDate.setHours(hours - offset);

  // const date_time = newDate.toLocaleString().split(", ")[0];

  // return (date_time + ", " + local_time);
  // console.log(utc)
};

export const calculateShift = (dateString: any) => {
  // Parse the input date string
  const date = new Date(dateString + "Z");
  // Get the hour from the date
  const hour = date.getHours();

  // Define shift boundaries
  const morningStart = 6;
  const morningEnd = 12;
  const afternoonStart = 12;
  const afternoonEnd = 16;
  const eveningStart = 16;
  const eveningEnd = 20;

  // Determine the shift based on the hour
  if (hour >= morningStart && hour < morningEnd) {
    return "Morning shift";
  } else if (hour >= afternoonStart && hour < afternoonEnd) {
    return "Afternoon shift";
  } else if (hour >= eveningStart && hour < eveningEnd) {
    return "Evening shift";
  } else {
    return "Night shift";
  }
};

export const formatMonth = (date: string) => {
  const d_array = date?.split("/");
  switch (d_array[1]) {
    case "01":
      return d_array[0] + " Jan " + d_array[2];
    case "02":
      return d_array[0] + " Feb " + d_array[2];
    case "03":
      return d_array[0] + " Mar " + d_array[2];
    case "04":
      return d_array[0] + " Apr " + d_array[2];
    case "05":
      return d_array[0] + " May " + d_array[2];
    case "06":
      return d_array[0] + " Jun " + d_array[2];
    case "07":
      return d_array[0] + " Jul " + d_array[2];
    case "08":
      return d_array[0] + " Aug " + d_array[2];
    case "09":
      return d_array[0] + " Sep " + d_array[2];
    case "10":
      return d_array[0] + " Oct " + d_array[2];
    case "11":
      return d_array[0] + " Nov " + d_array[2];
    case "12":
      return d_array[0] + " Dec " + d_array[2];
  }
};

export const formatMonthmmdd = (date: string) => {
  const d_array = date?.split("/");
  switch (d_array[0]) {
    case "01":
      return d_array[0] + " Jan " + d_array[2];
    case "02":
      return d_array[0] + " Feb " + d_array[2];
    case "03":
      return d_array[0] + " Mar " + d_array[2];
    case "04":
      return d_array[0] + " Apr " + d_array[2];
    case "05":
      return d_array[0] + " May " + d_array[2];
    case "06":
      return d_array[0] + " Jun " + d_array[2];
    case "07":
      return d_array[0] + " Jul " + d_array[2];
    case "08":
      return d_array[0] + " Aug " + d_array[2];
    case "09":
      return d_array[0] + " Sep " + d_array[2];
    case "10":
      return d_array[0] + " Oct " + d_array[2];
    case "11":
      return d_array[0] + " Nov " + d_array[2];
    case "12":
      return d_array[0] + " Dec " + d_array[2];
  }
};

//function to check if the user has permission to access the page / action
export const doesUserHasAccess = (permission_id: any) => {
  // return true
  const user_info_string: any = getLivisUserInfo();
  const user_info = JSON.parse(user_info_string);
  const isSuperuser = user_info?.is_superuser;
  if (isSuperuser) return true;
  const permissions = user_info?.user_permissions;
  const hasAcces = permissions?.includes(permission_id);
  return hasAcces;
};

// function to hide the console logs
export const hideConsoleLogs = () => {
  if (process.env.NODE_ENV === "production") {
    console.log = () => {
      return;
    };
    // console.error = () => { return };
    console.warn = () => {
      return;
    };
    console.info = () => {
      return;
    };
  }
};
