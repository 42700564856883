const initialState = "";

/**
 * workstationTypeReducer: Reducer to manage the state of the workstation type.
 * @param {any} state - The current state of the workstation type.
 * @param {any} action - The action to be processed.
 * @returns {any} - The updated state with the workstation type.
 */

const workstationTypeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_WORKSTATION_TYPE":
      return action?.payload;
      break;
    default:
      return state;
  }
};

export default workstationTypeReducer;
