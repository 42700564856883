import { defaultFormValues } from "../../modules/user-management/components/ManageUserForm/ManageUserFormJSON";

const initialState: any = defaultFormValues;

/**
 * addUserReducer: Reducer to handle actions related to user management data.
 *
 * @param {any} state - The current state of user management.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const addUserReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_USER_MANAGEMENT_DATA":
      return action?.payload;
      break;
    default:
      return state;
  }
};

export default addUserReducer;
