import { lazyImport } from "../helpers/lazyImport";
import Logout from "../modules/auth/components/Logout";
import { NotFound } from "../modules/misc";
import NoAccess from "../modules/misc/routes/NoAccess";
// import { AnnotateRoutes } from '../modules/Annotate';

const { InspectionStationRoutes }: any = lazyImport(
  () => import("../modules/inspection-station"),
  "InspectionStationRoutes"
);
const { UsecaseRoutes }: any = lazyImport(
  () => import("../modules/usecase"),
  "UsecaseRoutes"
);
const { PartRoutes }: any = lazyImport(
  () => import("../modules/part"),
  "PartRoutes"
);
const { TrainingRoutes }: any = lazyImport(
  () => import("../modules/training"),
  "TrainingRoutes"
);
const { TestingRoutes }: any = lazyImport(
  () => import("../modules/testing"),
  "TestingRoutes"
);
// const { AnnotateRoutes }: any = lazyImport(() => import('../modules/Annotate'), 'AnnotateRoutes');
const { OperatorPanelRoutes }: any = lazyImport(
  () => import("../modules/operator-panel"),
  "OperatorPanelRoutes"
);
const { ReportsRoutes }: any = lazyImport(
  () => import("../modules/report"),
  "ReportsRoutes"
);
const { UserRoutes }: any = lazyImport(
  () => import("../modules/user"),
  "UserRoutes"
);
const { OperatorPanelBuilderRoutes }: any = lazyImport(
  () => import("../modules/operator-panel-builder"),
  "OperatorPanelBuilderRoutes"
);
const { RecipeBuilderRoutes }: any = lazyImport(
  () => import("../modules/recipe-builder"),
  "RecipeBuilderRoutes"
);
const { TrashRoutes }: any = lazyImport(
  () => import("../modules/trash"),
  "TrashRoutes"
);
const { AnnoRoutes }: any = lazyImport(
  () => import("../modules/annotate_new"),
  "AnnoRoutes"
);
const { AnalyticsRoutes }: any = lazyImport(
  () => import("../modules/analytics"),
  "AnalyticsRoutes"
);
const { UserManagementRoutes }: any = lazyImport(
  () => import("../modules/user-management"),
  "UserManagementRoutes"
);
const { TagManagementRoutes }: any = lazyImport(
  () => import("../modules/tag-management"),
  "TagManagementRoutes"
);
const { AIInspectorRoutes }: any = lazyImport(
  () => import("../modules/ai-inspector"),
  "AIInspectorRoutes"
);
const { AuditLogRoutes }: any = lazyImport(
  () => import("../modules/audit-log"),
  "AuditLogRoutes"
);
const { DashboardRoutes }: any = lazyImport(
  () => import("../modules/dashboard"),
  "DashboardRoutes"
);

export const protectedRoutes = [
  {
    path: "/app/inspection/*",
    element: <InspectionStationRoutes />,
  },
  {
    path: "/app/usecase/*",
    element: <UsecaseRoutes />,
  },
  {
    path: "/app/part/*",
    element: <PartRoutes />,
  },
  {
    path: "/app/training/*",
    element: <TrainingRoutes />,
  },
  {
    path: "/app/testing/*",
    element: <TestingRoutes />,
  },
  {
    path: "/app/analytics/*",
    element: <AnalyticsRoutes />,
  },
  // {
  //   path: '/app/annotate_old/*',
  //   element: <AnnotateRoutes />,
  // },
  {
    path: "/app/annotate/*",
    element: <AnnoRoutes />,
  },
  {
    path: "/app/dashboard/*",
    element: <DashboardRoutes />,
  },
  {
    path: "/app/operator/*",
    element: <OperatorPanelRoutes />,
  },
  {
    path: "/app/reports/*",
    element: <ReportsRoutes />,
  },
  {
    path: "/app/builder/*",
    element: <OperatorPanelBuilderRoutes />,
  },
  {
    path: "/app/deployment/*",
    element: <RecipeBuilderRoutes />,
  },
  {
    path: "/app/logout/",
    element: <Logout />,
  },
  {
    path: "/app/user/*",
    element: <UserRoutes />,
  },
  {
    path: "/app/manage/*",
    element: <UserManagementRoutes />,
  },
  {
    path: "/app/tag-management/*",
    element: <TagManagementRoutes />,
  },
  {
    path: "/app/trash/*",
    element: <TrashRoutes />,
  },
  {
    path: "/app/inspector/*",
    element: <AIInspectorRoutes />,
  },
  {
    path: "/app/logs/*",
    element: <AuditLogRoutes />,
  },
  {
    path: "/app/no-access/*",
    element: <NoAccess />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
