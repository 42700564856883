import { useEffect, useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

const InputField = (props: any = null) => {
  const label: string = props.label ? props.label : "";
  const id: string = props.id ? props.id : "";
  const name: string = props.name ? props.name : "";
  const register: any = props.register ? props.register : {};
  const rules: any = props.rules ? props.rules : {};
  const toggle_icon: any = props.toggle_icon ? props.toggle_icon : false;
  const icon_img: any = props.icon ? props.icon : false;
  const toggle_icon_bold: any = props.toggle_icon_bold
    ? props.toggle_icon_bold
    : false;
  const icon_img_bold: any = props.icon_bold ? props.icon_bold : false;
  const matched: any = props.matched ? props.matched : false;
  const livis_on_change: any = props.livis_on_change
    ? props.livis_on_change
    : null;
  const inputRef: any = props.inputRef ? props.inputRef : null;
  const disabled: any = props.disabled ? props.disabled : false;
  const livis_on_blur: any = props.livis_on_blur ? props.livis_on_blur : null;

  const checkmark_icon = process.env.PUBLIC_URL + "/image/icons/checkmark.svg";

  const { t } = useTranslation();

  // eslint-disable-next-line prefer-const
  let [type, setTypeData] = useState(props.type);

  // eslint-disable-next-line prefer-const
  let [icon, setIconData] = useState(props.icon);
  const [icon_bold, setIconBold] = useState(props.icon_bold);
  useEffect(() => {
    if (matched) {
      setIconData(checkmark_icon);
    } else if (type === "password") {
      setIconData(icon_img);
    } else if (type === "text") {
      setIconData(toggle_icon);
    }
  }, [matched]);

  /**
   * Handles the toggle action for an icon button, switching the input type between 'text' and 'password',
   * and updating the icon based on the current state.
   *
   * @param {Event} event - The event object triggered by the button click.
   * @param {any} toggle_icon - The icon to be displayed when toggling.
   */
  const handleIconBtn = (event: any, toggle_icon: any) => {
    if (toggle_icon && type === "password" && !matched) {
      setTypeData("text");
      setIconData(toggle_icon);
      setIconBold(toggle_icon_bold);
    } else if (toggle_icon && type === "text" && !matched) {
      setTypeData("password");
      setIconData(icon_img);
      setIconBold(icon_img_bold);
    }
  };
  return (
    <>
      <TextField
        {...register(name, {
          ...rules,
          setValueAs: (value: any) =>
            typeof value === "string" ? value.trim() : value, // Trim leading and trailing spaces
        })}
        variant="standard"
        margin="normal"
        id={id}
        name={name}
        label={label}
        type={type === "email" ? "text" : type}
        className="livis-input-field"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {icon ? (
                <IconButton
                  data-testid="text-visible-button"
                  aria-label="toggle password visibility"
                  onClick={(e: any) => handleIconBtn(e, toggle_icon)}
                  edge="end"
                  tabIndex={-1}
                >
                  <img
                    src={icon}
                    onMouseOver={(e) =>
                      icon_img_bold && (e.currentTarget.src = icon_bold)
                    }
                    onMouseOut={(e) => (e.currentTarget.src = icon)}
                  />
                </IconButton>
              ) : null}
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ style: { fontFamily: "Noto Sans Regular" } }}
        onChange={livis_on_change}
        onBlur={livis_on_blur}
        inputRef={inputRef}
        disabled={disabled}
      />
      {(props.errors[name] && props.errors[name].message) || props.message ? (
        <small className="livis-text-danger">
          {props.errors[name] ? t(props.errors[name].message) : ""}
          {props.message ? t(props.message) : ""}
        </small>
      ) : (
        <small className="livis-empty-string"></small>
      )}
    </>
  );
};

export default InputField;
