const initialState: Array<any> = [];

/**
 * managePartDataListReducer: Reducer to handle actions related to the part data list.
 *
 * @param {any} state - The current state of the part data list.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const managePartDataListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_PART_DATA_LIST_DATA":
      return action?.payload;
      break;
    default:
      return state;
  }
};

export default managePartDataListReducer;
