import { useNavigate } from "react-router";
import { useEffect } from "react";
import { getLivisToken } from "../../../helpers/storage";
import { CircularProgress } from "@mui/material";

export const Landing = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const is_token_present = getLivisToken();
    if (is_token_present) {
      navigate("/app/inspection/station");
    } else {
      navigate("/auth/login");
    }
  });

  return (
    <div>
      <CircularProgress />
    </div>
  );
};
