import { defaultUsecaseFormValues } from "../../modules/usecase/components/AddUsecaseStepperForm/UsecaseFormJSON";

const initialState = {
  usecaseFormData: defaultUsecaseFormValues,
};

/**
 * usecaseFormReducer: Reducer to manage the use case form data.
 * @param {any} state - The current state of the use case form data.
 * @param {any} action - The action to be processed.
 * @returns {any} - The updated state with the use case form data.
 */

const usecaseFormReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_USECASE_FORM_DATA":
      return { usecaseFormData: action?.payload };
      break;
    default:
      return state;
  }
};

export default usecaseFormReducer;
