import { useForm } from "react-hook-form";
import {
  tag_category_list,
  sub_category_list,
  process_category_list,
  material_category_list,
} from "./UsecaseTagsListJSON";

export const defaultUsecaseFormValues = {
  usecase_name: "",
  usecase_desc: "",
  usecase_client_name: "",
  usecase_client_location: "",
  usecase_status: "active",
  usecase_tags: ["", "", "", "", ""],
  usecase_features: [],
  usecase_defects: [],
};

export const useUsecaseForm = () => {
  return useForm({
    mode: "all",
    defaultValues: defaultUsecaseFormValues,
  });
};

export const useImportDataForm = () => {
  return useForm({
    mode: "all",
    defaultValues: {},
  });
};

export const category_list = [
  material_category_list,
  tag_category_list,
  sub_category_list,
  process_category_list,
];
