const initialState = false;

/**
 * callGetTagReducer: Reducer to handle actions related to calling the get tag API.
 *
 * @param {any} state - The current state related to the get tag API call.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const callGetTagReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_CALL_GET_TAG":
      return action?.payload;
      break;
    default:
      return state;
  }
};

export default callGetTagReducer;
