import { CircularProgress, Backdrop } from "@mui/material";

const SpinnerTranslucent = (props: any) => {
  const { is_loading } = props;
  return (
    <Backdrop className="livis-spinner-loader-translucent" open={is_loading}>
      <CircularProgress />
    </Backdrop>
  );
};

export default SpinnerTranslucent;
