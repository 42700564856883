import { useForm } from "react-hook-form";
import PermissionDetails from "./PermissionDetails";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export const getAddPermissionSteps = () => {
  const { t } = useTranslation();
  const add_permission_steps = [
    {
      label: t("Permission Details"),
      component: PermissionDetails,
    },
  ];
  return add_permission_steps;
};

export const add_permission_steps = [
  {
    label: t("Permission Details"),
    component: PermissionDetails,
  },
];

export const defaultFormValues = {
  permission_name: "",
  permission_description: "",
  app_id: "",
};

export const permissionForm = () => {
  return useForm({
    mode: "all",
    defaultValues: defaultFormValues,
  });
};

export const getModules = () => {
  const { t } = useTranslation();
  const modules = [
    {
      label: t("Operator Panel"),
      value: "1",
    },
    {
      label: t("Reports Panel"),
      value: "2",
    },
  ];
  return modules;
};

export const modules = [
  {
    label: t("Operator Panel"),
    value: "1",
  },
  {
    label: t("Reports Panel"),
    value: "2",
  },
];
