import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../../components/Form/Input/InputField";
import LivisSwitch from "../../../../components/Switch/LivisSwitch";
import LivisCheckBox from "../../../../components/Form/Input/LivisCheckBox";
import { role_access, role_modules } from "./ManageRoleFormJSON";
import {
  listAllApps,
  listAllPermissionsByApp,
} from "../../../../services/userManagement";
import {
  setRoleManagementData,
  setUserManagementButton,
} from "../../../../store/actions";
import { formatString, removeUnderscores } from "../../../../helpers/utils";

const RoleDetails = (props: any) => {
  const { form } = props;
  const {
    register,
    setValue,
    getValues,
    control,
    watch,
    reset,
    formState: { errors },
  } = form;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [appData, setAppData] = useState<any>([]);
  const [selectedApp, setSelectedApp] = useState<any>();
  const [selectedAppPermissions, setSelectedAppPermissions] = useState<any>([]);
  const form_data = useSelector((state: any) => state.addRoleReducer);
  const firstInputRef = useRef<any>(null);

  const [showList, setShowList] = useState<any>(true);
  // const [expanded, setExpanded] = useState(false)
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const cheveron_up =
    process.env.PUBLIC_URL + "/image/icons/chevron_up_blue.svg";

  const validation_rule = {
    group_name: {
      required: {
        value: true,
        message: t("Please enter Role name"),
      },
      pattern: {
        value: /^[A-Za-z0-9_]+$/,
        message: t(
          "Role name can only contain alphanumeric characters and underscores"
        ),
      },
    },
    group_description: {
      required: {
        value: false,
        message: t("Please enter Description"),
      },
      minLength: {
        value: 2,
        message: t("Description must be at least 2 characters"),
      },
      maxLength: {
        value: 50,
        message: t("Description must be less than 50 characters"),
      },
    },
    status: {},
  };

  /**
   * Fetches user data on component mount.
   */
  useEffect(() => {
    getUserData("all");
  }, []);

  /**
   * Updates the user management button state based on form values.
   * Enables the button if group name and module values are valid.
   */
  useEffect(() => {
    ////console.log(getValues(), props.formType)
    // if (props.formType === "edit_permission") {
    //   if (getValues()?.permission_name?.length) {
    //     dispatch(setUserManagementButton(false))
    //   } else {
    //     dispatch(setUserManagementButton(true))
    //   }
    // } else {
    if (
      getValues()?.group_name?.length &&
      getValues()?.modules &&
      Object.keys(getValues()?.modules).length > 0
    ) {
      const allHaveValues = Object.values(getValues()?.modules)?.some(
        (innerArray: any) => innerArray?.length > 0
      );
      ////console.log(allHaveValues)
      if (allHaveValues) dispatch(setUserManagementButton(false));
      else dispatch(setUserManagementButton(true));
    } else {
      dispatch(setUserManagementButton(true));
    }
    // }
  }, [watch, getValues(), form]);

  /**
   * Fetches user data and initializes form data for editing roles if applicable.
   *
   * @param value - A string indicating the type of data to fetch.
   */
  const getUserData = async (value: string) => {
    const response = await listAllPermissionsByApp();
    const data = response?.data?.data;
    //console.log(data)
    setAppData(data);
    if (data?.length) {
      setSelectedApp(data[0]);
      setSelectedAppPermissions(data[0]?.permissions);
    }

    // setup the edit flow
    if (props.formType == "edit_role") {
      // return
      //console.log(form_data)
      // Initialize payload
      const payload: any = {
        group_name: form_data?.group_name,
        group_description: form_data?.group_description,
        modules: {},
      };

      // Iterate over permissions and organize them into modules
      form_data?.permission_list?.forEach((permission: any) => {
        const { permission_id } = permission;
        //console.log(permission_id)
        const app_id = data?.find((app: any) =>
          app.permissions.some((p: any) => p.permission_id == permission_id)
        )?.app_id;
        if (app_id) {
          payload.modules[app_id] = [
            ...(payload.modules[app_id] || []),
            permission_id.toString(),
          ];
        }
      });

      //console.log(payload);
      reset(payload);
      dispatch(setRoleManagementData(payload));
    }
  };

  /**
   * Handles app selection and updates selected app and its permissions.
   *
   * @param item - The selected app object.
   */
  const handleSelectApp = (item: any) => {
    setSelectedApp(item);
    setSelectedAppPermissions(item?.permissions);
  };

  /**
   * Handles checking/unchecking of an app and updates form data accordingly.
   *
   * @param e - The change event from the checkbox.
   * @param item - The app object being checked/unchecked.
   */
  const handleCheckedApp = (e: any, item: any) => {
    const { name, checked } = e.target;
    setSelectedApp(item);
    setSelectedAppPermissions(item?.permissions);
    ////console.log(item, name, checked)
    let payload = { ...form_data };
    if (checked) {
      payload = {
        ...form_data,
        modules: {
          ...form_data?.modules,
          [name]: [],
        },
      };
    } else {
      const { modules, ...rest } = form_data;
      delete modules[name];
      payload = {
        ...rest,
        modules: { ...modules },
      };
    }
    ////console.log(payload)
    reset(payload);
    dispatch(setRoleManagementData(payload));
  };

  /**
   * Handles selection of permissions.
   *
   * @param item - The selected permission object.
   */
  const handleSelectPermission = (item: any) => {
    ////console.log("first", item)
  };

  /**
   * Handles checking/unchecking of permissions and updates form data accordingly.
   *
   * @param e - The change event from the checkbox.
   * @param item - The permission object being checked/unchecked.
   */
  const handleCheckedPermission = (e: any, item: any) => {
    const { name, checked } = e.target;
    ////console.log(name, checked);

    let payload = { ...form_data };

    if (checked) {
      payload = {
        ...form_data,
        modules: {
          ...form_data.modules,
          [selectedApp?.app_id]: [
            ...(form_data?.modules?.[selectedApp.app_id] || []),
            name,
          ],
        },
      };
    } else {
      payload = {
        ...form_data,
        modules: {
          ...form_data.modules,
          [selectedApp?.app_id]: (
            form_data?.modules?.[selectedApp.app_id] || []
          ).filter((item: any) => item !== name),
        },
      };
    }

    ////console.log(payload);
    reset(payload);
    dispatch(setRoleManagementData(payload));
  };

  /**
   * Checks if a specific permission is currently checked.
   *
   * @param id - The ID of the permission to check.
   * @returns A boolean indicating whether the permission is checked.
   */
  const isPermissionChecked = (id: any) => {
    const values = getValues()?.modules?.[selectedApp?.app_id];
    return values?.some((item: any) => item == id);
  };

  /**
   * Handles input field changes and updates form data.
   *
   * @param e - The change event from the input field.
   */
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    ////console.log(name, value)
    const payload = {
      ...form_data,
      [name]: name === "group_name" ? value?.trim() : value,
    };

    // ////console.log(payload)
    reset(payload);
    dispatch(setRoleManagementData(payload));
  };

  /**
   * Checks if a specific module is currently checked.
   *
   * @param id - The ID of the module to check.
   * @returns A boolean indicating whether the module is checked.
   */
  const isModuleChecked = (id: any) => {
    if (getValues()?.modules)
      return Object.keys(getValues()?.modules).some((item: any) => item == id);

    return false;
  };

  /**
   * Handles window resize events to toggle list visibility.
   */
  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerWidth, showList);
      if (window.innerWidth >= 768) {
        setShowList(false);
      } else {
        setShowList(true);
      }
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [showList]);

  /**
   * Toggles the expansion state of an item.
   *
   * @param item - The item to toggle.
   */
  const handleExpand = (item: any) => {
    if (expanded == item) {
      setExpanded(false);
    } else {
      setExpanded(item);
    }
  };

  return (
    <div className="livis-add-station-wrap">
      <Grid container justifyContent={"center"}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <h4 className="livis-stepper-title">{t("Role Details")}</h4>
          <Box className="livis-part-data-stepper-margin">
            <Grid
              className="livis-inspection-station-add-form livis-recipe-container"
              container
              spacing={3}
            >
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Controller
                  name="group_name"
                  control={control}
                  rules={validation_rule["group_name"]}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <>
                      <InputField
                        required={true}
                        id="group_name"
                        name="group_name"
                        label={t("Role Name")}
                        type="text"
                        register={register}
                        rules={validation_rule["group_name"]}
                        errors={errors}
                        livis_on_change={(e: any) => {
                          onChange(e);
                          handleInputChange(e);
                        }}
                        inputRef={firstInputRef}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Controller
                  name="group_description"
                  control={control}
                  rules={validation_rule["group_description"]}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <>
                      <InputField
                        required={true}
                        id="group_description"
                        name="group_description"
                        label={t("Description")}
                        type="text"
                        register={register}
                        rules={validation_rule["group_description"]}
                        errors={errors}
                        livis_on_change={(e: any) => {
                          onChange(e);
                          handleInputChange(e);
                        }}
                      />
                    </>
                  )}
                />
              </Grid>
              {/* <Grid item lg={6} md={6} sm={12} xs={12} sx={{ marginTop: "5%" }}>
                <LivisSwitch
                  id="status"
                  name="status"
                  label={t("Status")}
                  register={register}
                  setValue={setValue}
                  rules={validation_rule['status']}
                  value={getValues()?.status === null ? true : getValues()?.status}
                />
              </Grid> */}
            </Grid>
          </Box>
          <div className="livis-user-management-details-margin"></div>
          <h4 className="livis-stepper-title">{t("Permission Details")}</h4>
          {showList ? (
            <>
              {appData?.map((item: any, index: any) => (
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="livis-operator-panel-info-wrap livis-reports-panel-preivew-info-wrap livis-add-parameter-bl-accordian-wrap"
                  key={`accoridan-permission-${index}`}
                >
                  <div className="livis-operator-panel-accordian-wrap livis-reports-preivew-accordian-wrap livis-define-bl-accordian-wrap">
                    <Accordion
                      expanded={expanded == `expand-${index}`}
                      className="livis-operator-builder-position-relative"
                    >
                      <AccordionSummary
                        expandIcon={<img src={cheveron_up} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="livis-operator-builder-position-relative"
                        onClick={() => {
                          handleExpand(`expand-${index}`);
                        }}
                      >
                        <Box className="livis-add-part-parameter-select-defect-feature-heading livis-add-part-parameter-bl-heading livis-permission-accordian-header">
                          {item?.app_name}
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid
                          container
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          justifyContent="space-between"
                        >
                          {item?.permissions?.map(
                            (permission: any, permissionIndex: any) => (
                              <div
                                className="livis-operator-builder-add-feature-modal-body-components livis-add-role-accordian-body-item"
                                id="operator-component-4"
                                key={`role_access_${permissionIndex}`}
                              >
                                <LivisCheckBox
                                  livis_on_change_function={(e: any) =>
                                    handleCheckedPermission(e, permission)
                                  }
                                  checked={isPermissionChecked(
                                    permission?.permission_id
                                  )}
                                  name={permission?.permission_id}
                                />
                                {removeUnderscores(permission?.permission_name)}
                              </div>
                            )
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </Grid>
              ))}
            </>
          ) : (
            <Box className="livis-part-data-stepper-margin">
              <Grid container spacing={3}>
                <Grid item lg={5} md={5} sm={6} xs={5}>
                  <div className="livis-create-role-permission-block">
                    <span>{t("All Modules")}</span>
                    <Box>
                      {appData?.map((item: any, index: any) => (
                        <div
                          className={`livis-operator-builder-add-feature-modal-body-components ${
                            item.app_id === selectedApp.app_id ? "active" : ""
                          } livis-add-permission-menu-item`}
                          id="operator-component-4"
                          onClick={() => handleSelectApp(item)}
                          key={`role_modules_${index}`}
                        >
                          {/* <LivisCheckBox
                            livis_on_change_function={(e: any) =>
                              handleCheckedApp(e, item)
                            }
                            checked={isModuleChecked(item?.app_id)}
                            name={item?.app_id}
                          /> */}
                          {item?.app_name}
                        </div>
                      ))}
                    </Box>
                  </div>
                </Grid>
                <Grid item lg={7} md={7} sm={6} xs={7}>
                  <div className="livis-create-role-permission-block">
                    <span>{t("Station Management")}</span>

                    {selectedAppPermissions?.map((item: any, index: any) => (
                      <div
                        className="livis-operator-builder-add-feature-modal-body-components"
                        id="operator-component-4"
                        key={`role_access_${index}`}
                      >
                        <LivisCheckBox
                          livis_on_change_function={(e: any) =>
                            handleCheckedPermission(e, item)
                          }
                          checked={isPermissionChecked(item?.permission_id)}
                          name={item?.permission_id}
                        />
                        {removeUnderscores(item?.permission_name)}
                      </div>
                    ))}
                  </div>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default RoleDetails;
