export const operatorPanelInitialState = {
  part_information: false,
  camera_feed: false,
  start_process: false,
  inspect: false,
  inspection_status: false,
  inspection_result: false,
  company_logo: false,
  health_check: false,
  inspection_history: false,
  inspection_count: false,
  threshold: false,
  production_plan: false,
};

/**
 * AddComponentToOperatorPanel: Reducer to handle actions related to adding a component to the operator panel.
 *
 * @param {any} state - The current state of the operator panel.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */

const AddComponentToOperatorPanel = (
  state = operatorPanelInitialState,
  action: any
) => {
  switch (action.type) {
    case "ADD_COMPONENT_TO_OPERATOR_PANEL":
      return {
        ...state, // Spread the existing state
        ...action.payload, // Override with the payload
      };
    default:
      return state;
  }
};

export default AddComponentToOperatorPanel;
