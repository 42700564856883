// import { defaultConfigJSON } from "../../modules/operator-panel-builder/components/configuration-panels/data";

const initialState: any = {};

/**
 * operatorBuilderJSON: Reducer to handle actions related to setting the current initial state of the operator builder.
 *
 * @param {any} state - The current state of the operator builder.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const operatorBuilderJSON = (state: any = initialState, action: any) => {
  switch (action.type) {
    case "SET_CURRENT_OPERATOR_BUILDER_INTITAL_STATE":
      return action?.payload;
    default:
      return state;
  }
};

export default operatorBuilderJSON;
