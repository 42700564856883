const initialStep = 0;

/**
 * stepReducer: Reducer to manage the current step in a multi-step process.
 * @param {any} state - The current state of the step.
 * @param {any} action - The action to be processed.
 * @returns {any} - The updated state based on the action type.
 */

const stepReducer = (state = initialStep, action: any) => {
  switch (action.type) {
    case "CHANGE_STEP":
      return action.payload;
      break;
    default:
      return state;
  }
};

export default stepReducer;
