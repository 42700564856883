import { combineReducers } from "redux";
import stepReducer from "./stepperReducer";
import spinnerReducer from "./spinnerReducer";
import workstationReducer from "./workstationReducer";
import workstationNextButtonReducer from "./workstationNextButton";
import inspectionStationListReducer from "./inspectionStationListReducer";
import usecaseNextButtonReducer from "./usecaseNextButtonReducer";
import usecaseListReducer from "./usecaseListReducer";
import usecaseFormReducer from "./usecaseFormReducer";
import chooseTagStepperReducer from "./chooseTagStepperReducer";
import partFormReducer from "./partFormReducer";
import partNextButtonReducer from "./partNextButtonReducer";
import partListReducer from "./partListReducer";
import selectPartReducer from "./selectPartReducer";
import managePartDataListReducer from "./managePartDataListReducer";
import manageImportDataFormReducer from "./manageImportDataFormReducer";
import workstationTypeReducer from "./workstationTypeReducer";
import selectOperatorBuilderComponentReducer from "./selectOperatorBuilderComponentReducer";
import AddComponentToOperatorPanel from "./AddComponentToOperatorPanel";
import OperatorBuilderAvailedComponents from "./OperatorBuilderAvailedComponents";
import operatorBuilderPreviewModal from "./operatorBuilderPreviewModal";
import operatorBuilderJSON from "./operatorBuilderJSON";
import operatorPanelPreviewDetails from "./operatorPanelPreviewDetails";
import trackHistroyOfOperatorPanelBuilder from "./trackHistroyOfOperatorPanelBuilder";
import onHoverOperatorBuilderComponent from "./onHoverOperatorBuilderComponent";
import operatorBuilderConfigPayload from "./operatorBuilderConfigPayload";
import recipeFormReducer from "./recipeFormReducer";
import recipeListReducer from "./recipeListReducer";
import recipeNextButtonReducer from "./recipeNextButtonReducer";
import addDataModalReducer from "./addDataModalReducer";
import spinnerReducerTranslucent from "./spinnerReducerTranslucent";
import SpinnerReducerBlock from "./SpinnerReducerBlock";
import reportTypeReducer from "./reportTypeReducer";
import reportPayloadReducer from "./reportPayloadReducer";
import reportResponseReducer from "./reportResponseReducer";
import sidebarAPIReducer from "./sidebarAPIReducer";
import TrainingStatusReducer from "./TrainingStatusReducer";
import importFormButton from "./importFormButton";
import addPartWithoutEdge from "./addPartWithoutEdge";
import addUserReducer from "./addUserReducer";
import addUserNextButton from "./addUserNextButton";
import addAppReducer from "./addAppReducer";
import addPermissionReducer from "./addPermissionReducer";
import addRoleReducer from "./addRoleReducer";
import partUnfilteredDataReducer from "./partUnfilteredDataReducer";
import skipConfigureStepperReducer from "./skipConfigureStepperReducer";
import skipPeripheralStepperReducer from "./skipPeripheralStepperReducer";
import callReportsReducer from "./callReportsReducer";

import importIncludeAnnotation from "./importIncludeAnnotation";

import reportImageVerification from "./reportImageVerification";
import agumentationDataReducer from "./agumentationDataReducer";
import tagDataReducer from "./tagDataReducer";
import usecaseParentIDReducer from "./usecaseParentIDReducer";
import callGetTagReducer from "./callGetTagReducer";
import isAgumentedReducer from "./isAgumentedReducer";
import skipOperatorLayoutStepperReducer from "./skipOperatorLayoutStepperReducer";

/**
 * rootReducer: Combines all individual reducers into a single root reducer.
 */
const rootReducer = combineReducers({
  step: stepReducer,
  skipConfigureStep: skipConfigureStepperReducer,
  skipPeripheralStep: skipPeripheralStepperReducer,
  skipOperatorLayoutStep : skipOperatorLayoutStepperReducer,
  spinner: spinnerReducer,
  spinnerTranslucent: spinnerReducerTranslucent,
  spinnerBlock: SpinnerReducerBlock,
  workstation: workstationReducer,
  workstationFormButton: workstationNextButtonReducer,
  inspectionStationData: inspectionStationListReducer,
  workstatonType: workstationTypeReducer,
  usecaseFormButton: usecaseNextButtonReducer,
  usecase: usecaseFormReducer,
  usecaseList: usecaseListReducer,
  chooseTagStep: chooseTagStepperReducer,
  partFormButton: partNextButtonReducer,
  part: partFormReducer,
  partList: partListReducer,
  partUnfilteredList: partUnfilteredDataReducer,
  selectedPart: selectPartReducer,
  managePartDataList: managePartDataListReducer,
  manageData: manageImportDataFormReducer,
  importFormButton: importFormButton,
  selectedOPBComponent: selectOperatorBuilderComponentReducer,
  AddComponentToOperatorPanel: AddComponentToOperatorPanel,
  OperatorBuilderAvailedComponents: OperatorBuilderAvailedComponents,
  operatorBuilderPreviewModal: operatorBuilderPreviewModal,
  operatorBuilderJSON: operatorBuilderJSON,
  operatorPanelPreviewDetails: operatorPanelPreviewDetails,
  trackHistroyOfOperatorPanelBuilder: trackHistroyOfOperatorPanelBuilder,
  onHoverOperatorBuilderComponent: onHoverOperatorBuilderComponent,
  operatorBuilderConfigPayload: operatorBuilderConfigPayload,
  recipe: recipeFormReducer,
  recipeList: recipeListReducer,
  recipeFormButton: recipeNextButtonReducer,
  addDataModal: addDataModalReducer,
  reportType: reportTypeReducer,
  reportPayload: reportPayloadReducer,
  reportResponse: reportResponseReducer,
  sidebarAPIReducer: sidebarAPIReducer,
  TrainingStatusReducer: TrainingStatusReducer,
  addPartWithoutEdge: addPartWithoutEdge,
  addUserReducer: addUserReducer,
  addUserNextButton: addUserNextButton,
  addAppReducer: addAppReducer,
  addPermissionReducer: addPermissionReducer,
  addRoleReducer: addRoleReducer,
  augmentationData: agumentationDataReducer,
  callReports: callReportsReducer,
  reportImageVerificationData: reportImageVerification,
  tagData: tagDataReducer,
  importIncludeAnnotation: importIncludeAnnotation,
  usecaseParentIDReducer: usecaseParentIDReducer,
  callGetTagReducer: callGetTagReducer,
  isAgumented: isAgumentedReducer,
});

export default rootReducer;
