import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getLivisToken } from "../../../helpers/storage";

export const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const is_token_present = getLivisToken();
    if (is_token_present) {
      navigate("/app/inspection/station");
    } else {
      navigate("/auth/login");
    }
  }, []);

  return <div>404</div>;
};
