import Axios from "axios";
import { t } from "i18next";
import { livisAlert } from "./alert";
import { LIVIS_API_BASE_URL } from "../config";
import {
  getAlertStatus,
  getLivisToken,
  getLivisUserInfo,
  getRefreshToken,
  removeAlertStatus,
  resetLivisUserInfo,
  setLivisUserInfo,
} from "./storage";

let refreshTokenPromise: Promise<boolean> | null = null;

// Function to refresh the access token
const refreshLivisToken = async () => {
  const refresh_token = getRefreshToken();
  if (!refresh_token) {
    resetLivisUserInfo(); // Logout if no refresh token
    return false;
  }

  // Check if a refresh token call is already in progress
  if (!refreshTokenPromise) {
    refreshTokenPromise = (async () => {
      try {
        // API call to get a new access token
        const response = await fetch(`${LIVIS_API_BASE_URL}authorization/refresh_token/`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({ refresh_token }),
        });

        if (response.status === 401) {
          resetLivisUserInfo(); // Logout if the refresh token is invalid
          window.location.href = "/";
          refreshTokenPromise = null; // Reset promise
          return false;
        }

        const data = await response.json();
        const user_info = data;
        const currentUserInfo = JSON.parse(getLivisUserInfo() || '{}');
        const new_user_info = {
          ...currentUserInfo,
          access_token: user_info?.data?.access_token,
          refresh_token: user_info?.data?.refresh_token,
        };

        if (new_user_info) {
          setLivisUserInfo(JSON.stringify(new_user_info)); // Store new tokens
          refreshTokenPromise = null; // Reset promise
          return true;
        } else {
          resetLivisUserInfo(); // Logout if the new tokens are invalid
          refreshTokenPromise = null;
          return false;
        }
      } catch (error) {
        console.error(error);
        refreshTokenPromise = null; // Reset promise in case of error
        return false;
      }
    })();
  }

  return refreshTokenPromise;
};

// Request interceptor to add Authorization headers
function authRequestInterceptor(config: any) {
  const token = getLivisToken();
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  config.headers.Accept = "application/json";
  config.headers["Content-Type"] = "application/json";
  return config;
}

// List of restricted URLs where no alerts should be shown
const restricted_url = [
  "parts/check_part_uniqueness/",
  "authorization/login/",
  "workstations/check_unique_workstation_name/",
  "usecase/check_usecase_uniqueness/",
  "parameters/get_all_parameters/"
];

// Axios instance with base URL setup
export const axios = Axios.create({
  baseURL:
    window.location.pathname === '/app/operator/report' || window.location.pathname === '/app/operator/home'
      ? 'http://localhost:9000/'
      : LIVIS_API_BASE_URL,
});

// Adding request interceptor
axios.interceptors.request.use(authRequestInterceptor);

// Adding response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.message === "Network Error") {
      livisAlert(error?.message, "error");
      return null;
    }

    const status = error?.response?.status;
    const err_msg = t(`${error?.response?.data?.message}`);
    const no_alert_status = getAlertStatus();
    const restrict_url = restricted_url.includes(error?.config?.url); // Correctly referencing the restricted URLs

    // Handle 401 errors with refresh token logic
    if (status === 401) {
      const access_token = await refreshLivisToken();
      if (access_token) {
        // Retry the original request with the new token
        error.config.headers["authorization"] = `Bearer ${getLivisToken()}`;
        return axios(error.config);
      } else {
        return error.response;
      }
    }

    // Handle other status codes and errors
    if (status === 403) {
      livisAlert("User does not have permission to perform this action", "warning");
    }

    if (!no_alert_status && !restrict_url) {
      switch (status) {
        case 400:
        case 403:
        case 404:
          livisAlert(err_msg, "error");
          break;
        case 500:
          livisAlert("Internal server error", "error");
          break;
        default:
          livisAlert("Unknown Error", "error");
      }
    }

    removeAlertStatus();
    return error.response;
  }
);
