import { axios } from "../helpers/axios";

export type LoginCredentialsDTO = {
  email: string;
  password: string;
};

export type ForgotPasswordType = {
  email: string;
};

export type ResetPasswordType = {
  user_name: string;
};

export type UpdatePasswordType = {
  user_name: string;
  password: string;
};

export type RefreshTokenType = {
  refresh_token: string;
};

// API for use login
/**
 * Authenticates a user with their email and password.
 * @param data - The login credentials containing email and password.
 * @returns A promise that resolves with the authentication response.
 */
export const loginWithEmailAndPassword = (
  data: LoginCredentialsDTO
): Promise<any> => {
  return axios.post("authorization/login/", data);
};

// API for reset password request
/**
 * Requests a password reset for the given user.
 * @param data - The data containing the user's username for resetting the password.
 * @returns A promise that resolves with the reset password response.
 */
export const resetPasswordRequest = (data: ResetPasswordType): Promise<any> => {
  return axios.post("authorization/reset_password/", data);
};

// API for token validation
/**
 * Validates the provided token.
 * @param token - The token to be validated.
 * @returns A promise that resolves with the token validation response.
 */
export const validateToken = (token: string): Promise<any> => {
  return axios.get(`authorization/token_validation/${token}`);
};

// API for Updating password
/**
 * Updates the password for the given user.
 * @param data - The data containing the username and new password.
 * @returns A promise that resolves with the password update response.
 */
export const updatePassword = (data: UpdatePasswordType): Promise<any> => {
  return axios.post(`authorization/update_password/`, data);
};

// API for getting new authorization info and refresh token validation
/**
 * Requests a new access token using a refresh token.
 * @param data - The data containing the refresh token.
 * @returns A promise that resolves with the new access token.
 */
export const refreshPasswordRequest = (
  data: RefreshTokenType
): Promise<any> => {
  return axios.post(`authorization/refresh_token/`, data);
};

//API for logout
/**
 * Logs out the user by invalidating the access token.
 * @param payload - The data required to logout the user.
 * @returns A promise that resolves with the logout response.
 */
export const logoutWithAccessToken = (payload: any): Promise<any> => {
  return axios.post(`authorization/logout/`, payload);
};
