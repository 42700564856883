import { useForm } from "react-hook-form";
import AppDetails from "./AppDetails";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export const AddAppSteps = () => {
  const { t } = useTranslation();
  const add_app_steps = [
    {
      label: t("App Details"),
      component: AppDetails,
    },
  ];
  return add_app_steps;
};

export const add_app_steps = [
  {
    label: t("App Details"),
    component: AppDetails,
  },
];

export const defaultFormValues = {
  app_name: "",
  // description: ""
};

export const appForm = () => {
  return useForm({
    mode: "all",
    defaultValues: defaultFormValues,
  });
};
