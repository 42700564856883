import { defaultFormValues } from "../../modules/user-management/components/ManageRoleForm/ManageRoleFormJSON";

const initialState: any = defaultFormValues;

/**
 * addRoleReducer: Reducer to handle actions related to role management data.
 *
 * @param {any} state - The current state of the role management.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const addRoleReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_ROLE_MANAGEMENT_DATA":
      return action?.payload;
      break;
    default:
      return state;
  }
};

export default addRoleReducer;
