const initialStep = false;

/**
 * skipConfigureStepperReducer: Reducer to manage the state for skipping the configure step.
 * @param {any} state - The current state of the configure step.
 * @param {any} action - The action to be processed.
 * @returns {any} - The updated state based on the action type.
 */

const skipConfigureStepperReducer = (state = initialStep, action: any) => {
  switch (action.type) {
    case "SKIP_CONFIGURE_STEP":
      return action.payload;
      break;
    default:
      return state;
  }
};

export default skipConfigureStepperReducer;
