import { useRoutes } from "react-router-dom";
import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";
import { Landing } from "../modules/misc";
import { getLivisToken } from "../helpers/storage";
import { Suspense, useEffect } from "react";

// Middleware
export const AppRoutes = () => {
  const is_token_present = getLivisToken();
  const commonRoutes = [{ path: "/", element: <Landing /> }];
  const routes = is_token_present ? protectedRoutes : publicRoutes;
  const element = useRoutes([...routes, ...commonRoutes]);

  return (
    <>
      <Suspense>{element}</Suspense>
    </>
  );
};
