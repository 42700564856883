import { CircularProgress, Backdrop } from "@mui/material";
const Spinner = (props: any) => {
  const { is_loading } = props;
  const spinner = process.env.PUBLIC_URL + "/image/icons/loader.gif";
  return (
    // <Backdrop
    //   className="livis-spinner-loader"
    //   open={is_loading}
    // >
    //   <CircularProgress />
    // </Backdrop>
    <>
      {is_loading && (
        <div className="livis-spinner-loader livis-svg-loader">
          <img src={spinner} alt="" className="livis-spinner-loader-image" />
        </div>
      )}
    </>
  );
};

export default Spinner;
