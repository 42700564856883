const initialState = "select_component";

/**
 * onHoverOperatorBuilderComponent: Reducer to handle actions related to the hover state of the operator builder component.
 *
 * @param {any} state - The current state of the operator builder component's hover state.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const onHoverOperatorBuilderComponent = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_ON_HOVER_OPERATOR_BUILDER_COMPONENT":
      return action?.payload;
      break;
    default:
      return state;
  }
};

export default onHoverOperatorBuilderComponent;
