import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";

const LivisAutocomplete = (props: any) => {
  const {
    name,
    label,
    control,
    rules,
    error,
    defaultValue,
    options,
    livis_on_change,
    disabled,
  } = props;

  /**
   * Handles the change event for an input field and triggers a custom function if provided.
   *
   * @param {Event} e - The event object triggered by the input change.
   */
  const handleChange = (e: any) => {
    if (livis_on_change) {
      livis_on_change(e);
    }
  };
  return (
    <div className="livis-form-dropdown-select">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, onBlur, value, name } }) => (
          <>
            <Autocomplete
              id={`${name}-label`}
              options={options}
              onChange={(e, data) => {
                onChange(data);
                handleChange(data);
              }}
              onBlur={onBlur}
              value={defaultValue ? defaultValue : value}
              className="livis-dropdown-label"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={label}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  disabled={disabled}
                />
              )}
              disabled={disabled}
            />

            {error ? (
              <small className="livis-text-danger">{error}</small>
            ) : (
              <small className="livis-empty-string"></small>
            )}
          </>
        )}
      />
    </div>
  );
};

LivisAutocomplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  control: PropTypes.any,
  rules: PropTypes.any,
  error: PropTypes.any,
  children: PropTypes.array,
  defaultValue: PropTypes.string,
  options: PropTypes.array,
  livis_on_change: PropTypes.any,
  disabled: PropTypes.bool,
};

export default LivisAutocomplete;
