import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const add_station_type_image =
  process.env.PUBLIC_URL + "/image/misc/add_station_type.svg";

const cobot_image = process.env.PUBLIC_URL + "/image/icons/cobot_icon.webp";
const conveyor_image =
  process.env.PUBLIC_URL + "/image/icons/conveyor_station.webp";
const static_image =
  process.env.PUBLIC_URL + "/image/icons/static_station.webp";
const inspectron_image =
  process.env.PUBLIC_URL + "/image/icons/inspectron.webp";
const mobile_image =
  process.env.PUBLIC_URL + "/image/icons/mobile_station.webp";
const cctv_image = process.env.PUBLIC_URL + "/image/icons/cctv.webp";

export const plc_values = {
  plc_name: "",
  communication_protocol: "",
  interface_address: "",
};

export const camera_values = {
  camera_name: "",
  camera_type: "",
  camera_address: "",
};

export const peripheral_device_values = {
  device_name: "",
  device_type: "",
  device_address: "",
};

export const useStationSteps = () => {
  const { t } = useTranslation();
  const station_type = [
    {
      id: "static-station",
      type: "static",
      label: t("Static Station"),
      image: static_image,
    },
    {
      id: "conveyor",
      type: "conveyor",
      label: t("Conveyor"),
      image: conveyor_image,
    },
    {
      id: "cctv",
      type: "cctv",
      label: t("CCTV"),
      image: cctv_image,
    },
    {
      id: "inspectron",
      type: "inspectron",
      label: t("WiTorch®"),
      image: inspectron_image,
    },
    {
      id: "mobile",
      type: "mobile",
      label: t("Mobile"),
      image: mobile_image,
    },
    {
      id: "co-bot",
      type: "cobot",
      label: t("Cobot"),
      image: cobot_image,
    },
  ];
  return station_type;
};
export const station_type = [
  {
    id: "static-station",
    type: "static",
    label: "Static Station",
    image: static_image,
  },
  {
    id: "conveyor",
    type: "conveyor",
    label: "Conveyor",
    image: conveyor_image,
  },
  {
    id: "cctv",
    type: "cctv",
    label: "CCTV",
    image: cctv_image,
  },
  {
    id: "inspectron",
    type: "inspectron",
    label: "WiTorch®",
    image: inspectron_image,
  },
  {
    id: "mobile",
    type: "mobile",
    label: "Mobile",
    image: mobile_image,
  },
  {
    id: "co-bot",
    type: "cobot",
    label: "Cobot",
    image: cobot_image,
  },
];

export const useCommunicationProtocol = () => {
  const { t } = useTranslation();
  const communication_protocol = [
    t("Modbus TCP"),
    t("Modbus RTU"),
    t("Profinet"),
    t("Socket / TCP"),
    t("Serial Communication"),
  ];
  return communication_protocol;
};

export const communication_protocol = [
  "Modbus TCP",
  "Modbus RTU",
  "Profinet",
  "Socket / TCP",
  "Serial Communication",
];

export const useCameraType = () => {
  const { t } = useTranslation();
  const camera_type = [
    t("IP Cam"),
    t("USB Cam"),
    t("GIG-E (Baumer)"),
    t("RTSP"),
    t("File System"),
    //  "GIG-E (Lucid)"
  ];
  return camera_type;
};

export const camera_type = [
  "IP Cam",
  "USB Cam",
  "GIG-E (Baumer)",
  "RTSP",
  "File System",
  //  "GIG-E (Lucid)"
];

export const usePeripheralDeviceType = () => {
  const { t } = useTranslation();
  const peripheral_device_type = [
    t("Keyence"),
    t("Cognex"),
    // "GIG-E (Baumer)",
    // "RTSP",
    // "File System"
  ];
  return peripheral_device_type;
};

export const peripheral_device_type = [
  "Keyence",
  "Cognex",
  // "GIG-E (Baumer)",
  // "RTSP",
  // "File System"
];

export const useCameraDirection = () => {
  const { t } = useTranslation();
  const camera_direction = [t("Right to Left"), t("Left to Right")];
  return camera_direction;
};

export const camera_direction = ["Right to Left", "Left to Right"];

export const useCameraControl = () => {
  const { t } = useTranslation();
  const camera_control = [t("Absolute"), t("Increment")];
  return camera_control;
};

export const camera_control = ["Absolute", "Increment"];

export const defaultFormValues = {
  workstation_name: "",
  workstation_plant_name: "",
  workstation_location: "",
  workstation_status: true,
  workstation_type: "",
  workstation_type_detail: {},
  plc: [plc_values],
  camera: [camera_values],
  peripheral_device: [peripheral_device_values],
};

export const useStationForm = () => {
  return useForm({
    mode: "all",
    defaultValues: defaultFormValues,
  });
};
