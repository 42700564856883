const initialState = "select_component";

/**
 * selectOperatorBuilderComponentReducer: Reducer to manage the state for the selected operator builder component.
 * @param {any} state - The current state of the operator builder component.
 * @param {any} action - The action to be processed.
 * @returns {any} - The updated state based on the action type.
 */
const selectOperatorBuilderComponentReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case "SET_OPERATOR_BUILDER_COMPONENT":
      return action?.payload;
      break;
    default:
      return state;
  }
};

export default selectOperatorBuilderComponentReducer;
