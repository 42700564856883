import { axios } from "../helpers/axios";

//API to get users details
export const getUserDetails = (): Promise<any> => {
  return axios.get(`user_management/get_user_details`);
};

//API to change the password
export const changeUserPassword = (payload: any): Promise<any> => {
  return axios.patch(`user_management/change_password/`, payload);
};

//API to get all users
export const getAllUsers = (payload: any): Promise<any> => {
  return axios.post(`authorization/list_all_users/`, payload);
};

//API to register a user
export const registerUser = (payload: any): Promise<any> => {
  return axios.post(`authorization/register_new/`, payload);
};

//API to update a user
export const updateUser = (payload: any): Promise<any> => {
  return axios.post(`authorization/update_user/`, payload);
};

//API to delete a user
export const deleteUser = (payload: any): Promise<any> => {
  return axios.post(`authorization/delete_user/`, payload);
};

//API to change status of user
export const changeUserStatus = (payload: any): Promise<any> => {
  return axios.post(`authorization/set_user_status/`, payload);
};

//API to add an APP
export const addApp = (payload: any): Promise<any> => {
  return axios.post(`authorization/add_app/`, payload);
};

//API to update an APP
export const updateApp = (payload: any): Promise<any> => {
  return axios.post(`authorization/update_app/`, payload);
};

//API to delete an APP
export const deleteApp = (payload: any): Promise<any> => {
  return axios.post(`authorization/delete_app/`, payload);
};

//API to get all APP
export const listAllApps = (payload: any): Promise<any> => {
  return axios.post(`authorization/list_all_apps/`, payload);
};

//API to change status of APP
export const changeAppStatus = (payload: any): Promise<any> => {
  return axios.post(`authorization/set_app_status/`, payload);
};

//API to add a permission
export const addPermission = (payload: any): Promise<any> => {
  return axios.post(`authorization/add_permissions/`, payload);
};

//API to update a permission
export const updatePermission = (payload: any): Promise<any> => {
  return axios.post(`authorization/update_permission/`, payload);
};

//API to delete a permission
export const deletePermission = (payload: any): Promise<any> => {
  return axios.post(`authorization/delete_permission/`, payload);
};

//API to get all permissions
export const listAllPermissions = (payload: any): Promise<any> => {
  return axios.post(`authorization/list_available_permissions/`, payload);
};

//API to change status of Permission
export const changePermissionStatus = (payload: any): Promise<any> => {
  return axios.post(`authorization/set_permission_status/`, payload);
};

//API to add a group
export const addRole = (payload: any): Promise<any> => {
  return axios.post(`authorization/create_group/`, payload);
};

//API to update a group
export const updateRole = (payload: any): Promise<any> => {
  return axios.post(`authorization/update_group/`, payload);
};

//API to delete a group
export const deleteRole = (payload: any): Promise<any> => {
  return axios.post(`authorization/delete_group/`, payload);
};

//API to get all groups
export const listAllRoles = (payload: any): Promise<any> => {
  return axios.post(`authorization/list_groups/`, payload);
};

//API to change status of Role
export const changeRoleStatus = (payload: any): Promise<any> => {
  return axios.post(`authorization/set_group_status/`, payload);
};

//API to list all permissions by apps

export const listAllPermissionsByApp = (): Promise<any> => {
  return axios.get(`authorization/list_permissions_by_app/`);
};

//API to list all the registered domains
export const getAllDomainsAPI = (): Promise<any> => {
  return axios.get(`authorization/get_all_domains/`);
};

//API to check email uniquness
export const checkUniqueEmail = (payload: any): Promise<any> => {
  return axios.post(`authorization/check_unique_email/`, payload);
};
