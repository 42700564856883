import { useForm } from "react-hook-form";
import RoleDetails from "./RoleDetails";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export const getRoleModules = () => {
  const { t } = useTranslation();
  const role_modules = [
    {
      label: t("Station Management"),
      value: "station_management",
    },
    {
      label: t("Use Case Module"),
      value: "usecase_module",
    },
    {
      label: t("Part Module"),
      value: "part_module",
    },
    {
      label: t("Data Collection"),
      value: "data_collection",
    },
    {
      label: t("User Management"),
      value: "user_management",
    },
    {
      label: t("Production Plans"),
      value: "production_plans",
    },
    {
      label: t("Training Module"),
      value: "training_module",
    },
  ];
  return role_modules;
};

export const role_modules = [
  {
    label: t("Station Management"),
    value: "station_management",
  },
  {
    label: t("Use Case Module"),
    value: "usecase_module",
  },
  {
    label: t("Part Module"),
    value: "part_module",
  },
  {
    label: t("Data Collection"),
    value: "data_collection",
  },
  {
    label: t("User Management"),
    value: "user_management",
  },
  {
    label: t("Production Plans"),
    value: "production_plans",
  },
  {
    label: t("Training Module"),
    value: "training_module",
  },
];

export const getRoleAccess = () => {
  const { t } = useTranslation();
  const role_access = [
    {
      label: t("View / Access all Stations"),
      value: "view",
    },
    {
      label: t("Edit / Update Station details"),
      value: "edit",
    },
    {
      label: t("Create / Duplicate Stations"),
      value: "create",
    },
    {
      label: t("Delete Stations"),
      value: "delete",
    },
  ];
  return role_access;
};

export const role_access = [
  {
    label: t("View / Access all Stations"),
    value: "view",
  },
  {
    label: t("Edit / Update Station details"),
    value: "edit",
  },
  {
    label: t("Create / Duplicate Stations"),
    value: "create",
  },
  {
    label: t("Delete Stations"),
    value: "delete",
  },
];

export const getAddRoleSteps = () => {
  const { t } = useTranslation();
  const add_role_steps = [
    {
      label: t("Role Details"),
      component: RoleDetails,
    },
  ];
  return add_role_steps;
};

export const add_role_steps = [
  {
    label: t("Role Details"),
    component: RoleDetails,
  },
];

export const defaultFormValues = {
  group_name: "",
  group_description: "",
  // status:true,
  modules: [],
};

export const rolesForm = () => {
  return useForm({
    mode: "all",
    defaultValues: defaultFormValues,
  });
};
